@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

.App {
	text-align: center;
	padding-bottom: 60px;
}

.LM {
	padding: 5px;
	width: 200px;
	max-width: 200px;
	height: auto;
}
.footer {
	flex-wrap: wrap;
	font-size: 0.8rem;
	position: fixed;
	/* background-color: aqua; */
	background-color: #f1f5f7;
	left: 0;
	bottom: 0;
	right: 0;
	padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
	.footer {
		display: none;
	}
}

.email {
	padding-left: 20px;
	padding-right: 10px;
}
.phone {
	padding-right: 10px;
}
