.sijainti {
  max-width: 25%;
  height: auto;
}
.toimipiste {
  max-width: 45%;
  height: auto;
}
.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #f1f5f7;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .sijainti {
    max-width: 90%;
    height: auto;
  }
  .toimipiste {
    max-width: 90%;
    height: auto;
  }
}
