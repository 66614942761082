.lennuKuva {
	max-width: 20%;
	height: auto;
	float: right;
	padding-top: 3%;
}
.teksti-container {
	display: flex;
	font-size: 1em;
	justify-content: center;
	flex-direction: column;
	padding-left: 25%;
	padding-right: 25%;
}
a:link,
a:visited {
	text-decoration: none;
	font-weight: bold;
}
.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}
.lennuKuva2 {
	max-width: 20%;
	height: auto;
	float: left;
	padding-bottom: 55px;
}

.para1 {
	padding-bottom: 15px;
	padding-left: 20%;
	padding-right: 20%;
	text-align: center;
}
.email {
	padding-left: 105px;
}
.location {
	padding-left: 105px;
}
.varaa {
	padding-left: 105px;
}
@media screen and (max-width: 768px) {
	.teksti-container {
		display: flex;
		font-size: 1.1em;
		flex-direction: column;
		padding-left: 1%;
		padding-right: 1%;
	}
	.lennuKuva2 {
		max-width: 50%;
		height: auto;
	}
	.lennuKuva {
		max-width: 40%;
		height: auto;
		padding-top: 3%;
	}
	.para1 {
		padding: 15px;
		text-align: center;
	}
}
