.hinnasto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.taulu {
  max-width: 50%;
}

.uusTaulu-container2 {
  display: flex;
  flex-direction: row;
  padding-left: 35%;
  padding-right: 35%;
}

.kasittely {
  font-size: 0.7rem;
}

.uusTaulu-container {
  display: flex;
  flex-direction: row;
  padding-left: 25%;
  padding-right: 25%;
}
@media screen and (max-width: 768px) {
  .uusTaulu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30%;
  }
  .uusTaulu-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30%;
    padding-right: 25%;
  }
}
