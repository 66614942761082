.para {
  padding: 1%;
  padding-left: 20%;
  padding-right: 20%;
}
.teksti-container {
  padding: 5%;
}
.kasvukipu {
  max-width: 20%;
  height: auto;
  padding: 2%;
}
.blogikuva {
  float: right;
  max-width: 100%;
  height: auto;
  padding-right: 42%;
  padding-top: 10%;
}
.rasituskuvat {
  display: flex;
  flex-direction: row;
}
.rasitusvamma {
  max-width: 80%;
  height: auto;
}
.osgoodvamma {
  padding-top: 4%;
  max-width: 60%;
  height: auto;
}
.kontra {
  padding-left: 40%;
  text-align: left;
}
.lihassarkoomereja {
  max-width: 80%;
  height: auto;
}
.sarko {
  font-size: 0.8rem;
}
@media screen and (max-width: 768px) {
  .para {
    padding: 1%;
    padding-right: 2%;
    font-size: 2.5vw;
  }
  .blogikuva {
    float: right;
    max-width: 30%;
    height: auto;
  }
  .lihassarkoomereja {
    float: right;
    max-width: 90%;
    height: auto;
  }
  .kasvukipu {
    float: right;
    max-width: 80%;
    height: auto;
    padding-right: 5%;
  }
  .kontra {
    padding-left: 10%;
    text-align: justify;
  }
}
