@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	text-align: center;
	padding-bottom: 60px;
}

.LM {
	padding: 5px;
	width: 200px;
	max-width: 200px;
	height: auto;
}
.footer {
	flex-wrap: wrap;
	font-size: 0.8rem;
	position: fixed;
	/* background-color: aqua; */
	background-color: #f1f5f7;
	left: 0;
	bottom: 0;
	right: 0;
	padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
	.footer {
		display: none;
	}
}

.email {
	padding-left: 20px;
	padding-right: 10px;
}
.phone {
	padding-right: 10px;
}

.lennuKuva {
	max-width: 20%;
	height: auto;
	float: right;
	padding-top: 3%;
}
.teksti-container {
	display: flex;
	font-size: 1em;
	justify-content: center;
	flex-direction: column;
	padding-left: 25%;
	padding-right: 25%;
}
a:link,
a:visited {
	text-decoration: none;
	font-weight: bold;
}
.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}
.lennuKuva2 {
	max-width: 20%;
	height: auto;
	float: left;
	padding-bottom: 55px;
}

.para1 {
	padding-bottom: 15px;
	padding-left: 20%;
	padding-right: 20%;
	text-align: center;
}
.email {
	padding-left: 105px;
}
.location {
	padding-left: 105px;
}
.varaa {
	padding-left: 105px;
}
@media screen and (max-width: 768px) {
	.teksti-container {
		display: flex;
		font-size: 1.1em;
		flex-direction: column;
		padding-left: 1%;
		padding-right: 1%;
	}
	.lennuKuva2 {
		max-width: 50%;
		height: auto;
	}
	.lennuKuva {
		max-width: 40%;
		height: auto;
		padding-top: 3%;
	}
	.para1 {
		padding: 15px;
		text-align: center;
	}
}

.grid-container {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  grid-gap: 50px 3%;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding-left: 35%;
  padding-right: 35%;
}
.paragraph {
  background-color: #f1f5f7;
  border-radius: 25px;
  border: 1px solid black;
  padding: 5%;
  text-align: center;
}
.palauteKuva {
  height: 27%;
  width: 27%;
  align-self: center;
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1vw;
  padding-bottom: 10%;
}
@media screen and (max-width: 1000px) {
  .form-container {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
    padding-right: 25%;
  }
}
@media screen and (max-width: 768px) {
  .palauteKuva {
    height: 60%;
    width: 60%;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.5vw;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 45% 45%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    grid-gap: 5% 5%;
  }
  .paragraph {
    background-color: #f1f5f7;
    border-radius: 25px;
    border: 1px solid black;
    padding: 5%;
    text-align: center;
  }
}

.para {
  padding: 1%;
  padding-left: 20%;
  padding-right: 20%;
}
.teksti-container {
  padding: 5%;
}
.kasvukipu {
  max-width: 20%;
  height: auto;
  padding: 2%;
}
.blogikuva {
  float: right;
  max-width: 100%;
  height: auto;
  padding-right: 42%;
  padding-top: 10%;
}
.rasituskuvat {
  display: flex;
  flex-direction: row;
}
.rasitusvamma {
  max-width: 80%;
  height: auto;
}
.osgoodvamma {
  padding-top: 4%;
  max-width: 60%;
  height: auto;
}
.kontra {
  padding-left: 40%;
  text-align: left;
}
.lihassarkoomereja {
  max-width: 80%;
  height: auto;
}
.sarko {
  font-size: 0.8rem;
}
@media screen and (max-width: 768px) {
  .para {
    padding: 1%;
    padding-right: 2%;
    font-size: 2.5vw;
  }
  .blogikuva {
    float: right;
    max-width: 30%;
    height: auto;
  }
  .lihassarkoomereja {
    float: right;
    max-width: 90%;
    height: auto;
  }
  .kasvukipu {
    float: right;
    max-width: 80%;
    height: auto;
    padding-right: 5%;
  }
  .kontra {
    padding-left: 10%;
    text-align: justify;
  }
}

.hinnasto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.taulu {
  max-width: 50%;
}

.uusTaulu-container2 {
  display: flex;
  flex-direction: row;
  padding-left: 35%;
  padding-right: 35%;
}

.kasittely {
  font-size: 0.7rem;
}

.uusTaulu-container {
  display: flex;
  flex-direction: row;
  padding-left: 25%;
  padding-right: 25%;
}
@media screen and (max-width: 768px) {
  .uusTaulu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30%;
  }
  .uusTaulu-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30%;
    padding-right: 25%;
  }
}

.sijainti {
  max-width: 25%;
  height: auto;
}
.toimipiste {
  max-width: 45%;
  height: auto;
}
.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #f1f5f7;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .sijainti {
    max-width: 90%;
    height: auto;
  }
  .toimipiste {
    max-width: 90%;
    height: auto;
  }
}

