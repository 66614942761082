.grid-container {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  grid-gap: 50px 3%;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding-left: 35%;
  padding-right: 35%;
}
.paragraph {
  background-color: #f1f5f7;
  border-radius: 25px;
  border: 1px solid black;
  padding: 5%;
  text-align: center;
}
.palauteKuva {
  height: 27%;
  width: 27%;
  align-self: center;
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1vw;
  padding-bottom: 10%;
}
@media screen and (max-width: 1000px) {
  .form-container {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
    padding-right: 25%;
  }
}
@media screen and (max-width: 768px) {
  .palauteKuva {
    height: 60%;
    width: 60%;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.5vw;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 45% 45%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    grid-gap: 5% 5%;
  }
  .paragraph {
    background-color: #f1f5f7;
    border-radius: 25px;
    border: 1px solid black;
    padding: 5%;
    text-align: center;
  }
}
